<template>
    <div class="Explore">
		<Header></Header>
		<div class="ExploreCon">
			<h3>市场集合 </h3>
			<ul class="dlHLLo cvGTYH">
				<li :class="number===0?'cur':''" @click="switchBtn(0)">最新</li>
				<!-- <li :class="number===1?'cur':''" @click="switchBtn(0)">最热</li> -->
				<li 
					v-for="(item,index) in rows" 
					:key="item.cteId" 
					:class="number===index+2?'cur':''"
					@click="switchBtn(index+2)"
				>
					{{item.cteName}}
				</li>
			</ul>
			<div style="padding:25px 20px;">
				<div class="card-carousel--card"
					:style="{'width':width + 'px','height':width + 'px'}" 
					v-for="item in dataRoot" 
					:key="item.cnId" 
					@click="detailBtn(item.cnId)"
				>
					<div class="card-carousel-box">
						<el-image 
							:style="{'width':width + 'px','height':height + 'px'}" 
							:src="item.cnFeatured" 
							:fit="fit" 
							style="object-fit: cover;"
						/>
						<div class="circle">
							<img :src="item.cnLogo">
						</div>
					</div>
					<div class="card-carousel--card--footer" :style="{'height':height + 'px'}">
						<div class="title">
							{{item.cnName}}
						</div>
						<div class="title" style="color:rgb(32, 129, 226);font-size:14px;margin-top:5px;">
							{{item.urName}}
						</div>
						<div class="con">
							{{item.cnDes}}
						</div>
					</div>
				</div>
			</div>
		</div>			
		<Footer></Footer>
    </div>
</template>
<script>
	import Header from "@/components/Header"
	import Footer from "@/components/Footer"
	import { ElLoading } from 'element-plus'
	import { ArrowDownBold } from '@element-plus/icons-vue'
	export default {
		components: { Header,Footer,ArrowDownBold,ElLoading },
		data() {
			return{
				number:0,
				isCollapse: false,
				width:'',
				height:'',
				rows:[],
				dataRoot:[],
				currentPage: 1,
				total: 0,
				pageSize:24,
			}
		},
		created(){
			let that = this;
			that.getListType();
			that.$nextTick(()=>{
				window.onscroll = function(){
					var scrollTop = document.documentElement.scrollTop||document.body.scrollTop;
					var windowHeight = document.documentElement.clientHeight || document.body.clientHeight;
					var scrollHeight = document.documentElement.scrollHeight||document.body.scrollHeight;
					if(scrollTop+windowHeight==scrollHeight){
						if(that.rows.length < that.total) {
							that.currentPage++;
							that.initData();
						}
					} 
				}
			})
		},
		methods:{
			detailBtn(cnId){
				this.$router.push({
					path:'/Explore/Collection/'+ cnId,
				})
			},
			switchBtn(index){
				let that = this;
				that.number = index;
				that.currentPage = 1;
				that.dataRoot = [];
				if(index >=2 ){
					that.initData(that.rows[index-2].cteId);
				}else{
					that.initData("");
				}
			},
			getListType(){
				let that = this;
				this.$axios({
					method:'post',
					url:'nft/cte/getAll',
					headers: {
						token:""
					},
					data:this.$qs.stringify({
						
					})
				})
				.then((res)=>{
					that.rows = res.data.dataRoot;
					let index = that.$route.params.type;
					if(index != "123"){
						that.rows.forEach((item)=>{
							let cteId = item.cteId;
							if(cteId == index-2){
								that.number = Number(index);
								this.initData(cteId);
							}
						})
					}else{
						that.initData("");
					}
				})
				.catch((err)=>console.log('请求失败',err))
			},
			initData(cteId){
				let that = this;
				let loading = ElLoading.service({
					lock: true,
					text: '正在加载',
					background: 'rgba(0, 0, 0, 0.7)',
				})
				this.$axios({
					method:'post',
					url:'nft/cn/getList',
					headers: {
						token:""
					},
					data:this.$qs.stringify({
						cteId: cteId,
						page: that.currentPage,
						limit: that.pageSize
					})
				})
				.then((res)=>{
					that.total = res.data.totalCounts;
					let rows = res.data.dataRoot;
					rows.forEach((item)=>{
						let pnPrice = item.pnPrice;
						if(pnPrice == "" || pnPrice == "0.00"){
							item.pnPrice = "0";
						}
					})
					that.dataRoot = that.dataRoot.concat(rows);
					loading.close();
				})
				.catch((err)=>console.log('请求失败',err))
			},
			getScreenWidthHeight(){
				let that = this;
				let w = document.documentElement.clientWidth || document.body.clientWidth;
				w = w -40;
				if(w <= 576){
					this.width = w;
					this.height = w/2
				}
				if(w > 576 && w < 1080){
					this.width = w/2 -40;
					this.height = this.width/2;
				}
				if(w > 1080 && w < 1400){
					this.width = w/3 - 30;
					this.height = this.width/2
				}
				if(w > 1400){
					this.width = w/4 - 30;
					this.height = this.width/2
				}
			}
		},
		beforeMount(){
			this.getScreenWidthHeight();
		},
		mounted(){
			window.addEventListener('resize', this.getScreenWidthHeight, false)
		},
		beforeDestroy(){
			window.removeEventListener('resize',this.getScreenWidthHeight, false)
		}
	};
</script>
<style scoped>
	.ExploreCon{
		min-height:700px;
	}
	.ExploreCon h3{
		text-align: center;
		font-size:28px;
		margin:25px 0px;
	}
	.dlHLLo{
		padding:0px 8px;
		border-bottom: 1px solid rgb(229, 232, 235);
		margin-top: 30px;
		margin: 0px;
		display: flex;
		width:100%;
		overflow-x: hidden;
		justify-content: center;
	}
	.dlHLLo li{
		padding: 12px 20px;
		font-size:20px;
		font-weight:bold;
		display: flex;
		-webkit-box-pack: center;
		height: 100%;
		color:#999;
	}
	li.cur{
		border-bottom:4px solid rgb(32, 129, 226);
		border-top-left-radius: 4px;
		border-top-right-radius: 4px;
		color:#000;
	}
</style>
